import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../Dialog/DialogCustom";
import Badge from '@material-ui/core/Badge';
import axios from "axios";
import envConfig from "../../bin/env.config";
import CircularProgress from "@material-ui/core/CircularProgress";
import VizSensor from "react-visibility-sensor";
import CompanyLogo from "./CompanyLogo";
const useStyles = makeStyles((theme) => ({
  productImage: {
    objectFit: "contain", //
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  productImageHover:{
    "&:hover": {
          cursor: "pointer",
          backgroundColor: "rgb(0 176 79 / 10%)",
        },
  },
  loading: {
    color: theme.palette.secondary.main,
  },
  badge: {
    top: '16px',
    // marginLeft: '-198px',
    left: '0px',
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    border:'1px solid #DDD',
    color:"#FFF",
    backgroundColor: '#DDD',
  },
}));
export function ImageDialog({    
  open,
  handleClose,
  imageList,
}) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  
  const handleClickImage =()=>{
    let n= imageList.length
    let nextIndex = selectedIndex+1
    nextIndex = nextIndex>=n? 0 : nextIndex
    setSelectedIndex(nextIndex)

  }
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  return (   
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="ImageDialog"
        open={open}
      >
        <DialogTitle id="image" onClose={handleClose}>
          {imageList.length > 0 &&(
            <ButtonGroup size="small" aria-label="small outlined button group">
              { imageList.map((row,i)=>(
                <Button key={i} variant={i==selectedIndex?"contained":"outlined"} onClick={()=>setSelectedIndex(i)}>{i+1}</Button>
              ))}          
            </ButtonGroup>
          )}
        
        </DialogTitle>
        <DialogContent dividers>
          <div style={{display:"flex",width:"100%",height:"400px",alignItems: "center", justifyContent:"center",position: "relative"}} onClick={handleClickImage}>
          <img
              className={classes.productImage}
              style={{ width: '300px',maxHeight:"400px"}}
              src={
                imageList[selectedIndex]
                  ? imageList[selectedIndex].image_url
                  : `https://bettercart.ca/resources/images/no-picture-available.png`
              }
              onError={addDefaultSrc}
            />
            {imageList[selectedIndex]&&<CompanyLogo company_id={imageList[selectedIndex].company_id} style={{position: "absolute",bottom:"0px", right:"0px",width:"100px"}}/>}
          </div>
        </DialogContent>
        
      </Dialog>    
  );
}
export default function ProductImage(props) {
  const classes = useStyles();
  const {product_id, product_matching_group_id,  company_logo_width, disabled} =props
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  
  const state = useSelector(state => state);
  const [isVisible, setIsVisible] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageSelectedIndex, setImageSelectedIndex] = useState(0);
  const handleClickOpen = () => {
    !disabled&&setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const apiUrl = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/product-images`;
      const postData = {
        product_id: product_id,
        product_matching_group_id:product_matching_group_id,
      };
      try {
        const result = await axios.post(apiUrl, postData,
          {
            headers: {
              Authorization: state.session.token,
            },
          });
        setImageList(result.data.data);        
      } catch (e) {
        console.log(e)
      }
      setIsLoading(false);
    };
    isVisible&& fetchDataSource();
  }, [isVisible,product_id, product_matching_group_id]);
  
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };  
  return (
    <VizSensor onChange={doLoadData}>
      <>
        {isLoading ? (          
          <CircularProgress className={classes.loading} />
        ) : (
          <Badge 
              badgeContent={imageList.length} 
              classes={{ badge: classes.badge }}>
              <div {...props} onClick={handleClickOpen}>
                <img                  
                  className={classes.productImage}
                  {...props}
                  src={
                    imageList[imageSelectedIndex]
                      ? imageList[imageSelectedIndex].image_url
                      : `https://bettercart.ca/resources/images/no-picture-available.png`
                  }
                  onError={addDefaultSrc}
                />
                {imageList[imageSelectedIndex]&&<CompanyLogo company_id={imageList[imageSelectedIndex].company_id} 
                style={{position: "absolute", bottom:"20px", right:"10px", width:company_logo_width?company_logo_width:'50px'}}/>}
              </div>              
          </Badge>
        )}
        <ImageDialog imageList={imageList} open={open} handleClose={handleClose}/>
      </>
    </VizSensor>
      
  );
}
